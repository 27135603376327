@font-face {
    font-family: 'Barlow Semi Condensed Black';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Black.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Black Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-BlackItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Bold';
    src: url('../../../assets/src/fonts/BarlowSemiCondensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Bold Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-BoldItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Extra Bold';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-ExtraBold.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Extra Bold Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-ExtraBoldItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Extra Bold Light';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-ExtraLight.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Italic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Light';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Light.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Light Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-LightItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Medium';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Medium.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Medium Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-MediumItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Regular';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Regular.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Semi Bold';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-SemiBold.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Semi Bold Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-SemiBoldItalic.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Thin';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-Thin.ttf' format('truetype');
}

@font-face {
    font-family: 'Barlow Semi Condensed Thin Italic';
    src: '../../../assets/src/fonts/BarlowSemiCondensed-ThinItalic.ttf' format('truetype');
}