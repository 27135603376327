.modal-header {
    background-color: $primaryColor;
    color: $tertiaryColor;
    font-family: 'Barlow Semi Condensed Semi Bold', sans-serif;

    .modal-title {
        font-size: 1rem;
    }

    .close {
        outline: none !important;
        color: $tertiaryColor;
        text-shadow: 0 1px 0 $shadowColor;
    }
}

.modal-footer {
    background-color: darken($tertiaryColor, 10%);
}

.modal-loader {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.off {
        display: none;
    }

    .spinner-border {
        color: $primaryColor;
    }

    p {
        font-size: 0.9rem;
        margin: 1rem 0 0;
    }
}

.modal {
    &--news {
        .modal-header {
            height: 200px;
            display: flex;
            align-items: center;

            .modal-title {
                text-align: center;
                width: 100%;
                font-size: 1.7rem;
            }

            .close {
                display: none;
            }
        }

        .modal-body {
            font-family: 'Barlow Semi Condensed Medium', sans-serif;
            font-size: 0.9rem;
        }

        .modal-footer {
            background-color: unset;
            justify-content: center;
        }
    }

    &--whats-app {
        .modal-header {
            display: none;
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .modal-footer {
            background-color: unset;
            justify-content: center;
        }
    }

    &--newspaper {
        .modal-header {
            border: none;
        }

        .modal-body {
            padding: 0;

            object {
                width: 100%;
                min-height: 85vh;
            }
        }

        @media (min-width: 576px) {
            .modal-lg,
            .modal-xl {
                max-width: 95%;
            }
        }
    }
}