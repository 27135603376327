.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $primaryColor;
}

.drp-buttons {
    .btn {
        line-height: unset;

        &:hover {
            color: #fff;
        }
    }
}