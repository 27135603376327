$primaryColor: #ef0216;
$secondaryColor: #272727;
$tertiaryColor: #f8f9fa;
$accentColor: #656565;
$shadowColor: #000;
$inputColor: #F8F9FA;

$dangerTextColor: #721c24;
$dangerBgColor: #f8d7da;
$dangerBorderColor: #f5c6cb;
$successTextColor: #155724;
$successBgColor: #d4edda;
$successBorderColor: #c3e6cb;
$warningTextColor: #856404;
$warningBgColor: #fff3cd;
$warningBorderColor: #ffeeba;