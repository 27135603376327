.form {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    background-color: lighten($primaryColor, 48%);

    &__container {
        width: 80%;
        max-width: 960px;
        display: flex;
        flex-direction: column;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 2rem;

        h2 {
            color: $primaryColor;
            font-size: 2rem;
            font-family: 'Barlow Semi Condensed Semi Bold', sans-serif;
            font-weight: bolder;
            letter-spacing: -0.1rem;
        }

        img {
            width: 130px;
            margin: 10px 0 0;
        }
    }

    form {
        display: grid;
        gap: 2rem;
        grid-template-columns: 100%;

        @media (min-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }

        .input-container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            font-family: 'Barlow Semi Condensed Regular', sans-serif;
            position: relative;
            z-index: 11;

            &--full-width {
                width: 100%;

                @media (min-width: 800px) {
                    grid-row: 4;
                    grid-column: 1 / 3;
                }

                .bootstrap-select {
                    width: 100% !important;
                    border: 1px solid $primaryColor;
                    border-radius: 0;

                    .dropdown-toggle,
                    .dropdown-menu {
                        font-size: 0.9rem;
                    }
                }
            }

            &--phone {
                z-index: 100;

                input {
                    padding: 0.6rem 1rem 0.6rem 6.5rem !important;
                }

                .iti__selected-flag {
                    background: #00000014;
                    padding: 0 0.8rem;
                }

                .iti__selected-dial-code {
                    font-size: 0.9rem;
                }
            }

            label {
                color: $primaryColor;
                margin: 0 0 3px;
                font-size: 0.8rem;
            }

            input,
            select {
                border: 1px solid $primaryColor;
                padding: 0.6rem 1rem;
                font-size: 0.9rem;
                width: 100%;
            }
        }

        .agreements {
            margin: 1rem 0 0;
            z-index: 10;
            position: relative;

            @media (min-width: 800px) {
                grid-row: 5;
                grid-column: 1/3;
            }

            &__item {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                margin: 0 0 10px;

                &:nth-child(2) {
                    margin: 0 0 10px 22px;
                }

                input {
                    margin: 2px 0 0 0;
                }

                label {
                    flex-grow: 1;
                    font-family: 'Barlow Semi Condensed Regular', sans-serif;
                    font-size: 0.9rem;
                    line-height: 1rem;
                    cursor: pointer;

                    b {
                        font-weight: bolder;
                    }

                    a {
                        color: $primaryColor;
                        transition: all 0.5s;
                        text-decoration: none;
                        font-weight: bolder;

                        &:hover {
                            color: darken($primaryColor, 10%);
                        }
                    }
                }
            }
        }

        .button-container {
            display: flex;
            justify-content: center;

            @media (min-width: 800px) {
                grid-row: 6;
                grid-column: 1/3;
            }
        }
    }

    .form-loader {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        &.off {
            display: none;
        }

        .spinner-border {
            color: $primaryColor;
        }

        p {
            font-family: 'Barlow Semi Condensed Regular', sans-serif;
            font-size: 1.2rem;
            margin: 1rem 0 0;
        }
    }
}