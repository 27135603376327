.error-page {
	background-color: $secondaryColor;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	&__container {
		display: flex;
		flex-direction: row;
	}

	&__logo {
		border-right: 1px solid $tertiaryColor;
		padding: 0 20px 0 0;
		display: flex;
		align-items: center;

		img {
			height: 120px;
		}
	}

	&__main {
		padding: 0 20px;

		h1 {
			font-size: 12rem;
			color: $tertiaryColor;
			font-weight: 800;
			letter-spacing: 5px;
			text-shadow: 0 0 15px $shadowColor;
		}

		.code {
			font-size: 2rem;
			color: $tertiaryColor;
			text-shadow: 0 0 15px $shadowColor;
			text-align: center;

			span {
				color: $primaryColor;
			}
		}

		.message {
			font-size: 1.5rem;
			color: $tertiaryColor;
			text-shadow: 0 0 15px $shadowColor;
			text-align: center;
			width: 350px;
		}
	}
}
