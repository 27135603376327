.news {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;

    h2 {
        font-family: "Barlow Semi Condensed Medium", sans-serif;
        font-size: 2rem;
        margin: 0 0 2rem;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
        width: 80%;
        max-width: 960px;

        @media (min-width: 800px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        justify-content: space-between;
        box-shadow: 0 0 15px #e6e6e6;
        border-bottom-left-radius: 14px;
        border-top-right-radius: 14px;

        img {
            width: 100%;
            border-radius: 10px;
        }

        h3 {
            font-family: "Barlow Semi Condensed Bold", sans-serif;
            margin: 1rem 0;
            text-align: center;
            font-size: 1.3rem;
        }

        .button {
            padding: 0 0.5rem;

            @media (min-width: 800px) {
                padding: 0 1.5rem;
            }
        }
    }
}