.swiper-pagination {
    &-bullet-active {
        background: $primaryColor;
    }
}

.swiper-button {
    &-prev,
    &-next {
        height: 100%;
        width: 8em;
        top: 22px;
        opacity: 0;
        background-image: rgb(0, 0, 0);
        transition: 0.2s ease-out;

        &::after {
            color: #e7e7e7;
            transition: 0.4s ease-out;
        }

        @media (max-width: 800px) {
            display: none;
        }
    }

    &-prev {
        left: 0;
        background-image: linear-gradient(90deg, #1a1a1a 0%, #000000b3 52%, #00000000 100%);
        opacity: 0;

        &:hover {
            opacity: 1;

            &::after {
                margin-left: 0;
                opacity: 1;
            }
        }

        &::after {
            margin-left: 40px;
        }
    }

    &-next {
        right: 0;
        background-image: linear-gradient(90deg, #00000000 0%, #000000b3 52%, #1a1a1a 100%);

        &:hover {
            opacity: 1;

            &::after {
                margin-right: 0;
                opacity: 1;
            }
        }

        &::after {
            margin-right: 40px;
        }
    }
}