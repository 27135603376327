.button {
    background-color: $primaryColor;
    border: 1px solid $primaryColor;
    font-family: "Barlow Semi Condensed Bold", sans-serif;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: .1rem;
    line-height: 38px;
    padding: 0 3rem;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    transition: all 0.4s;

    &:hover {
        background-color: darken($primaryColor, 15%);
    }

    &--outline {
        background-color: transparent;
        color: $primaryColor;
        border-color: $primaryColor;

        &:hover {
            background-color: $primaryColor;
            color: #fff;
        }
    }
}