.bootstrap-select {
    font-family: 'Barlow Semi Condensed Regular', sans-serif;

    .dropdown-menu {
        max-width: 100%;

        input[type="search"] {
            box-sizing: border-box;
        }
    }
}