.carousel {
    position: relative;

    @media (min-width: 800px) {
        height: 100vh;
    }

    &__heading {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom-left-radius: 14px;
        border-top-right-radius: 14px;
        padding: 2rem;
        box-sizing: border-box;

        @media (min-width: 800px) {
            position: absolute;
            bottom: 20vh;
            right: 10vw;
            width: 360px;
            background-color: #ffffffc7;
            padding: 2rem 2rem 0;
        }

        h1 {
            font-family: 'Barlow Semi Condensed Regular', sans-serif;
            font-size: 1.5rem;

            span {
                font-family: 'Barlow Semi Condensed Bold', sans-serif;
            }
        }

        p {
            font-family: 'Barlow Semi Condensed Medium', sans-serif;
            line-height: 1.5rem;
            margin: 1rem 0;

            @media (min-width: 800px) {
                margin: 1rem 0 0;
            }
        }

        .button {
            width: fit-content;
            padding: 0 2rem;

            @media (min-width: 800px) {
                position: relative;
                top: 1rem;
            }
        }
    }

    .swiper-slide {
        img {
            width: 100vw;
            object-fit: cover;

            @media (min-width: 500px) {
                height: 50vh;
            }

            @media (min-width: 800px) {
                height: 100vh;
            }

            &.mobile {
                display: block;

                @media (min-width: 800px) {
                    display: none;
                }
            }

            &.desktop {
                display: none;

                @media (min-width: 800px) {
                    display: block;
                }
            }
        }
    }
}