.contact {
    padding: 3rem 0;
    display: flex;
    justify-content: center;

    &__container {
        display: grid;
        grid-template-columns: 100%;
        gap: 2rem;
        width: 80%;
        max-width: 960px;

        @media (min-width: 800px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__left {
        h3 {
            font-family: 'Barlow Semi Condensed Bold', sans-serif;
            font-size: 2rem;
            line-height: 1.9rem;
            margin: 1.5rem 0;
        }

        p {
            font-family: 'Barlow Semi Condensed Black Italic', sans-serif;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    &__right {
        h3 {
            font-family: 'Barlow Semi Condensed Bold', sans-serif;
            font-size: 2rem;
            line-height: 1.9rem;
            margin: 1.5rem 0;
        }

        .whats-app-content {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            @media (min-width: 800px) {
                flex-direction: row;
            }

            p {
                flex-grow: 1;
                display: flex;

                &:nth-child(1) {
                    align-items: center;
                    font-family: 'Barlow Semi Condensed Regular', sans-serif;
                    margin: 10px 0 0;
                    cursor: pointer;
                    transition: 0.5s;

                    &:hover {
                        color: $primaryColor;
                    }

                    @media (min-width: 800px) {
                        margin: auto;
                    }

                    img {
                        height: 45px;
                        margin: 0 5px 0 0;
                    }
                }

                &:nth-child(2) {
                    justify-content: flex-end;

                    img {
                        width: 180px;
                    }
                }
            }
        }
    }
}

.modal-contact-body {
    .shop-select {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            font-family: 'Barlow Semi Condensed Bold', sans-serif;
            margin: 0 0 5px;
            font-size: 1.2rem;
        }

        .bootstrap-select {
            width: 100% !important;

            button {
                border-color: #dae0e5;
            }
        }
    }

    .whatsapp-button {
        background-color: #02e777;
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        justify-content: center;
        border-radius: 0.5rem;
        line-height: 2.5rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-family: 'Barlow Semi Condensed Bold', sans-serif;
        margin: 1rem 0 0;
        padding: 0 2rem;

        img {
            filter: brightness(0) invert(1);
            height: 1.3rem;
            margin: 0 0 0 0.5rem;
        }
    }
}