.badge {
    display: inline-block;
    font-size: 12px;
    background: #c3c5c7;
    color: #fff;
    border-radius: 15px;
    padding: 2px 10px;
    font-family: "Barlow Semi Condensed Semi Bold", sans-serif;
    text-transform: uppercase;

    &--processing {
        background: #3c85de;
    }

    &--success {
        background: #32a852;
    }

    &--error {
        background: #e3292c;
    }

    &--warning {
        background: #deb83c;
    }

    &--regular {
        background: $primaryColor;
    }

    &--outlet {
        background: #3e80c2;
    }

    &--popup {
        background: #32a852;
    }
}