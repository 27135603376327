.navbar {
    position: fixed;
    width: 100vw;
    z-index: 1001;
    background-color: #fff;
    border-bottom: 1px solid #dcdcdc;
    transition: top 0.2s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    font-family: 'Barlow Semi Condensed Black', sans-serif;

    &--open {
        @media (max-width: 800px) {
            top: 0;

            .navbar {
                &__content {
                    width: 100vw;
                    position: absolute;
                    top: 50px;
                    box-sizing: border-box;
                    background-color: #ececec;
                    padding: 0 0 1rem;
                    display: flex;
                    flex-direction: column-reverse;
                    box-shadow: 0px 14px 10px 0px #0000006b;

                    .mobile-icon {
                        position: absolute;
                        right: 1rem;
                        top: -55px;

                        .hamburger-menu {
                            span {
                                &:nth-child(1) {
                                    transform: translateY(6px) rotate(135deg);
                                }

                                &:nth-child(2) {
                                    opacity: 0;
                                    transform: translateX(-60px);
                                }

                                &:nth-child(3) {
                                    transform: translateY(-6px) rotate(-135deg);
                                }
                            }
                        }
                    }

                    .social-media-icons {
                        display: flex;
                        justify-content: center;
                        margin: 1rem 2rem 0;
                    }

                    .regular-menu {
                        display: flex;
                        flex-direction: column;

                        li {
                            padding: 1rem 0;
                            margin: 0;
                            box-sizing: border-box;

                            &:hover {
                                background-color: #dcdcdc;
                            }

                            a {
                                margin: 0 0 0 25px;

                                img {
                                    width: 30px;
                                    object-fit: contain;
                                    object-position: right;
                                    display: flex;
                                    align-items: center;
                                }
                            }
                        }
                    }

                    >li {
                        margin: 1rem 0.5rem 0;

                        &:not(:first-child) {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    &__logotype {
        display: block;
        width: 100px;
        margin: 0 0 0 1rem;

        img {
            width: 100%;
        }
    }

    &__content {
        width: 100px;
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;

        .mobile-icon {
            display: block;
            margin: 0 1.5rem 0 0;

            @media (min-width: 800px) {
                display: none;
            }

            .hamburger-menu {
                position: relative;
                width: 18px;
                height: 13px;
                margin: 7px;

                span {
                    opacity: 1;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 1px;
                    border-radius: 10px;
                    color: #000;
                    background-color: #000;
                    position: absolute;
                    transform: rotate(0deg);
                    transition: 0.4s ease-in-out;

                    &:nth-child(1) {
                        top: 0;
                    }

                    &:nth-child(2) {
                        top: 6px;
                    }

                    &:nth-child(3) {
                        top: 12px;
                    }
                }
            }
        }

        .social-media-icons {
            display: none;
            align-items: center;
            gap: 2rem;
            margin: 0 2rem 0 1rem;

            @media (min-width: 800px) {
                display: flex;
            }
        }

        .regular-menu {
            display: none;

            @media (min-width: 800px) {
                display: flex;
            }

            li {
                margin: 0 1.5rem 0 0;
            }
        }

        li {
            a {
                display: flex;
                text-decoration: none;
                align-items: center;
                color: #000;
                transition: .2s;
                cursor: pointer;
                position: relative;
                top: 1px;

                &:hover {
                    color: $primaryColor;

                    img {
                        filter: invert(100%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(.8);
                    }

                    .facebook {
                        transition: 0.4s ease-in-out;
                        filter: invert(100%) grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
                        -webkit-filter: invert(100%) grayscale(100%) brightness(30%) sepia(100%) hue-rotate(-180deg) saturate(700%) contrast(0.8);
                    }

                    .youtube {
                        transition: 0.4s ease-in-out;
                        filter: invert(100%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
                        -webkit-filter: invert(100%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
                    }

                    .whatsapp {
                        transition: 0.4s ease-in-out;
                        filter: invert(100%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
                        -webkit-filter: invert(100%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(50deg) saturate(1000%) contrast(0.8);
                    }
                }

                img {
                    height: 20px;
                    transition: .2s;
                }

                span {
                    font-family: 'Barlow Semi Condensed Bold', sans-serif;
                    margin: 0 0 0 5px;
                    text-transform: uppercase;
                    font-size: 0.9rem;
                }
            }
        }
    }

    .map-scroll {
        img {
            width: 16px;
        }
    }
}