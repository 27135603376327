@import "base/reset";
@import "base/fonts";

@import "abstracts/variables";
@import "abstracts/mixins";
@import "abstracts/animations";

@import "layout/navigation";
@import "layout/carousel";
@import "layout/news";
@import "layout/map";
@import "layout/contact";
@import "layout/benefits";
@import "layout/form";

@import "pages/error";
@import "pages/dashboard";

@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/spinners";

@import "../../../node_modules/leaflet/dist/leaflet.css";
@import "../../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "../../../node_modules/aos/src/sass/aos";

@import "../../../node_modules/swiper/swiper";
@import "../../../node_modules/swiper/modules/pagination/pagination";
@import "../../../node_modules/swiper/modules/navigation/navigation";
@import "../../../node_modules/swiper/modules/autoplay/autoplay";

@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

@import "../../../node_modules/daterangepicker/daterangepicker.css";

@import "components/buttons";
@import "components/badges";
@import "components/modals";
@import "components/scrollbar";
@import "components/selection";

@import "vendors_extensions/leaflet";
@import "vendors_extensions/bootstrap-select";
@import "vendors_extensions/swiper";
@import "vendors_extensions/daterangepicker";

.main-container {
    overflow-x: hidden;
}