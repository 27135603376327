.statistics {
    display: flex;

    &__item {
        display: flex;
        align-items: center;
        width: 100px;
        flex-grow: 1;
        margin: 10px;
        background-color: lighten($inputColor, 10%);
        box-shadow: 2px 3px 4px #00000029;

        i {
            font-size: 1.2rem;
            background: $primaryColor;
            color: #fff;
            width: 50px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            display: flex;
            flex-direction: column;
            margin: 10px;

            span {
                &:first-child {
                    font-weight: 100;
                    margin: 0 0 2px;
                }

                &:last-child {
                    font-size: 2rem;
                    font-weight: 600;
                }
            }

        }
    }
}