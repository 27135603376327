.benefits {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;

    &__container {
        width: 80%;
        max-width: 960px;
        border: 1px solid $primaryColor;
        box-sizing: border-box;
        border-bottom-left-radius: 14px;
        border-top-right-radius: 14px;
        padding: 3rem 2rem;
        background-color: #fff;

        h3 {
            font-family: 'Barlow Semi Condensed Semi Bold', sans-serif;
            font-size: 2rem;
            margin: 0 0 2rem;
            text-align: center;

            span {
                font-family: 'Barlow Semi Condensed Extra Bold', sans-serif;
            }
        }

        >p {
            font-family: 'Barlow Semi Condensed Regular', sans-serif;
            text-align: center;
            max-width: 400px;
            margin: 0 auto 2rem;
            line-height: 1.6rem;
        }

        .benefits-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            @media (min-width: 800px) {
                flex-direction: row;
            }

            &__item {
                flex-grow: 1;
                width: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    height: 100px;
                }

                p {
                    font-family: 'Barlow Semi Condensed Regular', sans-serif;
                    line-height: 1.6rem;
                    margin: 1rem 0 0;
                    text-align: center;
                }
            }
        }
    }
}