::-webkit-scrollbar {
	width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
	background: none;
}

::-webkit-scrollbar-thumb {
	background: $primaryColor;
}

::-webkit-scrollbar-thumb:hover {
	background: darken($primaryColor, 10%);
}
