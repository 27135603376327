.map {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;

    &__container {
        display: grid;
        grid-template-columns: 100%;
        gap: 2rem;
        width: 80%;
        max-width: 960px;

        @media (min-width: 800px) {
            grid-template-columns: 2fr 1fr;
        }
    }

    &__object {
        height: 400px;
        background-color: #eee;
        grid-row: 2;

        @media (min-width: 800px) {
            grid-row: auto;
        }
    }

    &__description {
        display: flex;
        flex-direction: column;
        grid-row: 1;

        @media (min-width: 800px) {
            grid-row: auto;
        }

        img {
            width: 130px;
        }

        h3 {
            font-family: 'Barlow Semi Condensed Bold', sans-serif;
            font-size: 2rem;
            line-height: 1.9rem;
            margin: 1.5rem 0;
        }

        p {
            font-family: 'Barlow Semi Condensed Black Italic', sans-serif;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}