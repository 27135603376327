.leaflet-container {
    // * TODO: kod tylko nieco poprawiony po partaczu - w wolnej chwili zrefactorować tu i w `\assets\src\js\global\map.js w funkcji mapMarkerTemplate`
    .leaflet-tile-pane {
        filter: grayscale(100%);
    }

    .leaflet-shadow-pane {
        opacity: 0.5;
    }

    .leaflet-popup-content-box {
        &-header {
            div {
                margin: 0 2px;
                display: inline-block;
                font-family: "Barlow Semi Condensed Bold", sans-serif;
                font-size: 15px;
                text-transform: uppercase;
            }
        }

        &-body {
            margin: 5px 0;

            div {
                margin: 0 2px;
                font-family: "Barlow Semi Condensed Regular", sans-serif;
                font-size: 14px;
                text-transform: uppercase;
            }

            &-olx,
            &-email,
            &-phone,
            &-second-line-address,
            &-first-line-address {
                width: 100%;
                margin: 2px 0 0 0;

                a {
                    margin: 0 2px;
                    font-family: "Barlow Semi Condensed Regular", sans-serif;
                    font-size: 14px;
                    text-decoration: none;
                    color: $primaryColor;
                }

                i {
                    &::before {
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }

                div,
                a {
                    display: inline-block;
                }
            }
        }

        .badge {
            font-size: 12px !important;
        }
    }

    .leaflet-control-attribution {
        a {
            color: $primaryColor;
        }
    }
}